<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb :crumbs='generateBreadCrumb()'></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-overlay :show="isBusy">
        <b-card>
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form
              class="needs-validation"
              @submit.prevent="handleSubmit(itemFormSubmit)"
              novalidate
            >
              <h6 class="heading-small text-muted mb-4">Content</h6>

              <div class="">
                <b-row>

                  <b-col lg="6">
                    <base-input
                      type="text"
                      :name="'Title - English'"
                      label="Title - English"
                      :required="true"
                      placeholder="Title - English"
                      v-model="itemForm.title.en"
                    >
                    </base-input>
                  </b-col>
                  <b-col lg="6">
                    <base-input
                      type="text"
                      :name="'Title - Arabic'"
                      label="Title - Arabic"
                      :required="true"
                      placeholder="Title - Arabic"
                      v-model="itemForm.title.ar"
                    >
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                 
                     <base-input
                      :name="'Sort Order'"
                      label="Sort Order"
                      :required="true"
                       placeholder="Sort Order"
                      type="number"
                       v-model="itemForm.sort_order"
                    >
                   
                    </base-input>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group>
                      <label class="form-control-label">Visible</label>
                      <b-form-checkbox class="mb-3" v-model="itemForm.visible">
                        Hide from mobile app
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>

                
              </div>

              <base-button type="primary" native-type="submit" class="my-4">
                {{ isUpdate() ? "Update Information" : "Create" }}
              </base-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-overlay>
    </b-container>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb.vue";
import MediaInput from "@/components/Inputs/MediaInput.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor.vue";
import * as Repo from "@/api-repo/healthstatuses";

import { handleError, successMessage } from "@/util/error-handler";
import BaseMultiSelect from "@/components/Inputs/BaseMultiSelect.vue";
export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    MediaInput,
    HtmlEditor,
    BaseMultiSelect,
  },
  props: ["type"],
  data() {
    return {
      isBusy: false,
      jobs: [
        { name: "Advisor", value: "advisor" },
        // {name: 'Drs Note', value: 'drs'}
      ],
     
      itemForm: {
        title: { en: null, ar: null },
        sort_order: { en: null, ar: null },
        visible: null,
      },
    };
  },
  created() {
    this.getInitData();
    if (this.isUpdate()) {
      this.getData();
    }
  },
  methods: {
    generateBreadCrumb() {
      return [{label: "Health Conditions", route: "HealthStatuses"},{label: this.isUpdate() ? this.itemForm.title.en : "Add New Health Condition"}];
    },
    getInitData: async function () {
      this.itemForm.title  = {en: null, ar: null};
    },
    getData: async function () {
      try {
        this.isBusy = true;
        let result = await Repo.getById(
          this.$route.params.id,
          this.userStore.state.token
        );
        
        this.itemForm.title = result.data.data.title || {en: null, ar: null};
        this.itemForm.sort_order = result.data.data.sort_order ? parseInt(result.data.data.sort_order) :null;
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },

    isUpdate: function () {
      return this.$route.params.id != null;
    },

    mapJobs(jobs) {
      if (!jobs) {
        return [];
      }
      return this.jobs.filter((e) => jobs.find((job) => e.value === job));
    },

    itemFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        //this.itemForm.visible = this.itemForm.visible;
        this.itemForm.sort_order = this.itemForm.sort_order ? parseInt(this.itemForm.sort_order): null;
        const objectForSubmission = {
          ...this.itemForm,
        };

        if (!this.isUpdate()) {
          try {
            this.isBusy = true;
            await Repo.create(
              objectForSubmission,
              this.userStore.state.token
            );
            successMessage("Item created successfully");
            this.$router.replace({ path: "/content/healthstatuses/" });
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        } else {
          try {
            this.isBusy = true;
            await Repo.update(
              this.$route.params.id,
              objectForSubmission,
              this.userStore.state.token
            );
            successMessage("Item updated successfully");
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        }
      }
    },
  },
};
</script>
